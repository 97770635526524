// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(
                str-slice($string, $index + str-length($search)),
                $search,
                $replace
            );
    }

    @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face(
    $name,
    $path,
    $weight: null,
    $style: null,
    $exts: ttf
) {
    $src: null;

    $extmods: (
        eot: '?',
        svg: '#' + str-replace($name, ' ', '_')
    );

    $formats: (
        otf: 'opentype',
        ttf: 'truetype'
    );

    @each $ext in $exts {
        $extmod: if(
            map-has-key($extmods, $ext),
            $ext + map-get($extmods, $ext),
            $ext
        );
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append(
            $src,
            url(quote($path + '.' + $extmod)) format(quote($format)),
            comma
        );
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}

//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;
    }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) {
        @content;
    }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) {
        @content;
    }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}

// A map of breakpoints.
// $breakpoints: (
//     xs: 319px,
//     sm: 768px,
//     md: 992px,
//     lg: 1200px
// );

// //
// //  RESPOND ABOVE
// //––––––––––––––––––––––––––––––––––––––––––––––––––

// // @include respond-above(sm) {}
// @mixin respond-above($breakpoint) {
//     // If the breakpoint exists in the map.
//     @if map-has-key($breakpoints, $breakpoint) {
//         // Get the breakpoint value.
//         $breakpoint-value: map-get($breakpoints, $breakpoint);

//         // Write the media query.
//         @media (min-width: $breakpoint-value) {
//             @content;
//         }

//         // If the breakpoint doesn't exist in the map.
//     } @else {
//         // Log a warning.
//         @warn 'Invalid breakpoint: #{$breakpoint}.';
//     }
// }

// //
// //  RESPOND BELOW
// //––––––––––––––––––––––––––––––––––––––––––––––––––

// // @include respond-below(sm) {}
// @mixin respond-below($breakpoint) {
//     // If the breakpoint exists in the map.
//     @if map-has-key($breakpoints, $breakpoint) {
//         // Get the breakpoint value.
//         $breakpoint-value: map-get($breakpoints, $breakpoint);

//         // Write the media query.
//         @media (max-width: ($breakpoint-value - 1)) {
//             @content;
//         }

//         // If the breakpoint doesn't exist in the map.
//     } @else {
//         // Log a warning.
//         @warn 'Invalid breakpoint: #{$breakpoint}.';
//     }
// }

// //
// //  RESPOND BETWEEN
// //––––––––––––––––––––––––––––––––––––––––––––––––––

// // @include respond-between(sm, md) {}
// @mixin respond-between($lower, $upper) {
//     // If both the lower and upper breakpoints exist in the map.
//     @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper)
//     {
//         // Get the lower and upper breakpoints.
//         $lower-breakpoint: map-get($breakpoints, $lower);
//         $upper-breakpoint: map-get($breakpoints, $upper);

//         // Write the media query.
//         @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
//             @content;
//         }

//         // If one or both of the breakpoints don't exist.
//     } @else {
//         // If lower breakpoint is invalid.
//         @if (map-has-key($breakpoints, $lower) == false) {
//             // Log a warning.
//             @warn 'Your lower breakpoint was invalid: #{$lower}.';
//         }

//         // If upper breakpoint is invalid.
//         @if (map-has-key($breakpoints, $upper) == false) {
//             // Log a warning.
//             @warn 'Your upper breakpoint was invalid: #{$upper}.';
//         }
//     }
// }
