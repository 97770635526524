nav {
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 3em;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 1111111;
  // background-color: $primary-color;
  background: rgb(255, 200, 54);
  background: linear-gradient(
    90deg,
    rgba(255, 200, 54, 1) 0%,
    rgba(255, 200, 54, 1) 14%,
    rgba(255, 255, 255, 1) 86%
  );

  .logo {
    display: block;
    float: left;
    text-decoration: none;
    position: relative;
    top: 0.5em;
    img {
      width: 1.5em;
      position: relative;
      top: -0.35em;
      margin-right: 0.2em;
    }
    span {
      color: $darkBlueColor;
    }
  }

  ul {
    margin: 1em 0 0 0;
    padding: 0;
    list-style: none;
    overflow: hidden;

    li {
      @include for-phone-only {
        &:first-child {
          margin-bottom: 1rem;
        }
      }
    }

    li a {
      display: block;
      // padding: 20px 20px;
      border-right: 1px solid $greyColor;
      text-decoration: none;
      color: $greyColor;
    }

    li {
      &:last-child {
        a {
          border-right: none;
        }
      }
    }
  }

  @include for-phone-only {
    .right {
      //   margin-right: 1rem;
      .menu-icon {
        display: inline-block;
        margin-bottom: 1rem;
        .navicon {
          color: $greyColor;
        }
      }
    }
    ul {
      margin: 0;
      display: inline-block;
      li {
        // &:first-child {
          margin-bottom: 1.5rem;
        // }
      }
    }
  }

  .menu {
    clear: both;
    // width: 300px;
    // max-height: 0;

    margin: 0;

    @include for-phone-only {
      width: 250px;
      transition: all 0.2s ease-out;
      right: -400px;
      position: absolute;
      // transition: max-height 0.2s ease-out;
      z-index: 11111;
      // background-color: $primary-color;
      opacity: 0;
      height: 100vw;
      margin: 3rem 0rem 0rem 0rem;
      padding: 1.5rem 0rem 1rem 1.5rem;
      top: 2rem;
      // height: 100%;
      display: inline-block;

      background-color: #ffffff;
      box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
        rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
        rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px,
        rgba(0, 0, 0, 0.07) 0px 32px 64px;

      li a {
        // padding: 1.5em 1em 0em 1em;
        padding-bottom: 1rem;
        border: 0;
        border-bottom: 1px solid $lightGreyColor;
      }
      li {
        &:last-child {
          a {
            padding-bottom: 0;
            border: none;
          }
        }
      }
    }
  }
  .menu-icon {
    cursor: pointer;
    float: right;
    position: relative;
    user-select: none;
    right: 2em;
    top: 1.5em;
  }

  .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }

  .menu-icon .navicon:before,
  .menu-icon .navicon:after {
    background: #333;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  .menu-icon .navicon:before {
    top: 5px;
  }

  .menu-icon .navicon:after {
    top: -5px;
  }

  /* menu btn */

  .menu-btn:hover {
    background-color: #f4f4f4;
  }

  .menu-btn {
    display: none;
  }

  .menu-btn:checked ~ .menu {
    // max-height: 240px;
    // right: 1em;
    opacity: 1;
    right: 0;
  }

  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }

  .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
    background: $greyColor;
  }

  .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
    background: $greyColor;
  }

  .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }

  /* 48em = 768px */

  @media (min-width: 48em) {
    li {
      float: left;
    }
    li a {
      padding: 1em 1em;
    }
    .menu {
      clear: none;
      float: right;
      max-height: none;
      position: relative;
      right: 1em;
    }
    .menu-icon {
      display: none;
    }
  }

  .left {
    a {
      &:first-child {
        font-size: 1.5em;
        text-decoration: none;
      }
      img {
        width: 1.5em;
        position: relative;
        top: -0.35em;
        margin-right: -0.2em;
      }

      &:nth-child(2) {
        margin-left: 2em;
      }
    }
  }
}
