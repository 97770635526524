// Fonts
@include font-face(SourceCodePro-Black, fonts/SourceCodePro-Black);
@include font-face(SourceCodePro-Bold, fonts/SourceCodePro-Bold);
@include font-face(SourceCodePro-ExtraLight, fonts/SourceCodePro-ExtraLight);
@include font-face(SourceCodePro-Light, fonts/SourceCodePro-Light);
@include font-face(SourceCodePro-Medium, fonts/SourceCodePro-Medium);
@include font-face(SourceCodePro-Regular, fonts/SourceCodePro-Regular);
@include font-face(SourceCodePro-Semibold, fonts/SourceCodePro-Semibold);

@include font-face(TimesNewRoman-Regular, fonts/TimesNewRoman-400);
@include font-face(TimesNewRoman-Bold, fonts/TimesNewRoman-Gras-700);
@include font-face(TimesNewRoman-Italic, fonts/TimesNewRoman-Italique-400);
@include font-face(
    TimesNewRoman-Italic-Bold,
    fonts/TimesNewRoman-Gras-Italique-700
);


@include font-face(Lato-Regular, fonts/Lato-Regular);
@include font-face(Lato-Bold, fonts/Lato-Bold);
@include font-face(Lato-BoldItalic, fonts/Lato-BoldItalic);
@include font-face(Lato-Thin, fonts/Lato-Thin);
@include font-face(Lato-Light, fonts/Lato-Light);

// Colors
$orangeColor: #ffc836;
$lightBlueColor: #4f9da6;
$blueColor: #317ad3;
$darkBlueColor: #233142;
$redColor: #ff5959;
$darkRedColor: #ff0000;
$deepPurple: #6335b9;
$greyColor: #353535;
$lightGreyColor: #f2f2f7;

$primary-color: $orangeColor;
