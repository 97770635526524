body {
  // font-family: TimesNewRoman-Regular;
  font-family: SourceCodePro-Regular;
  // background-color: $primary-color;
}

p {
  font-size: 1em;
  @include for-phone-only {
    font-size: 1.5rem;
  }
}
#loading {
  position: fixed; /* Sit on top of the page content *
    width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 1); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
#loading-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: black;
}
#spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  transform-style: preserve-3d;
  perspective: 800px;

  .arc {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-bottom: 3px solid $orangeColor;

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation: rotate#{$i} 1.15s linear infinite;
      }
    }

    &:nth-child(1) {
      animation-delay: -0.8s;
    }

    &:nth-child(2) {
      animation-delay: -0.4s;
    }

    &:nth-child(3) {
      animation-delay: 0s;
    }
  }
  @keyframes rotate1 {
    from {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(0);
    }

    to {
      transform: rotateX(35deg) rotateY(-45deg) rotateZ(1turn);
    }
  }

  @keyframes rotate2 {
    from {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(0);
    }

    to {
      transform: rotateX(50deg) rotateY(10deg) rotateZ(1turn);
    }
  }

  @keyframes rotate3 {
    from {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(0);
    }

    to {
      transform: rotateX(35deg) rotateY(55deg) rotateZ(1turn);
    }
  }
}

main {
  // margin-top:3em;
  position: relative;
  top: 5em;
  background: white;

  section {
    padding: 1em;
  }

  p {
    margin: 0;
    padding: 0;
  }

  strong,
  em {
    font-family: Lato-Bold;
    // font-family: TimesNewRoman-Bold;
    // font-family: SourceCodePro-Bold;
    color: $greyColor;
  }

  h3 {
    font-size: 2em;
    margin-bottom: 1em;
    line-height: 1.3;

    @include for-phone-only {
      margin-bottom: 1.5em;
      text-align: left;
    }
  }

  h5 {
    font-family: SourceCodePro-Black;
    margin-left: 1em;
  }

  h4,
  h5 {
    &:nth-child(2) {
      margin-bottom: 2em;
    }
    line-height: 1.5;
    @include for-phone-only {
      text-align: left;
      margin-left: 1em 0 1em 0;
    }
  }

  // code blocks
  div.highlighter-rouge {
    background-color: $darkBlueColor;
    padding: 0.5em;
    color: white;
    font-family: SourceCodePro-ExtraLight;
    line-height: 1.5em;
    margin: 1em;
    overflow: auto;
    // max-width: 80%;
    @include for-phone-only {
      max-width: 100%;
    }

    code {
      overflow: auto;
      font-size: 0.8em;
    }
  }

  code.highlighter-rouge {
    border-radius: 5px;
    padding: 0.1rem 0.3rem;
    /* font-style: italic; */
    background-color: lightsteelblue;
    font-family: SourceCodePro-ExtraLight;
    color: black;
    font-size: 1rem;
    font-weight: bold;
  }

  em {
    .highlighter-rouge {
      font-family: SourceCodePro-Semibold;
      background-color: transparent;
      color: $deepPurple;
      // font-style: italic;
      font-weight: 800;
      font-size: 0.8rem;
      margin: 0;
      padding: 0;
    }
  }

  p {
    margin: 1em;
  }

  section.main {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .left {
      margin-top: 1em;
      max-width: 46em;
      float: left;
      text-align: left;
      font-family: Lato-Regular;
      // font-family: TimesNewRoman-Regular;

      h3,
      h4 {
        font-family: SourceCodePro-Regular;
      }
      strong {
        font-family: SourceCodePro-Bold;
      }

      @include for-phone-only {
        float: none;
        text-align: center;
        padding-bottom: 3rem;
      }
      p {
        margin-bottom: 4em;
        @include for-phone-only {
          font-size: 1em;
          text-align: left;
          margin-left: 2em;
        }
      }
      .cta-button {
        background-color: $blueColor;
        color: white;
        border-radius: 10px;
        padding: 1em 2em;
        border: 0;
        font-family: SourceCodePro-Regular;
        text-decoration: none;
        @include for-phone-only {
          margin: 0 0 3em 0;
          padding: 1em 1em 1em 1em;

          // font-size: 1em;
        }
      }
      .play-store-link {
        display: block;
        margin: 0 0 3rem 0;
        padding: 0;
        width: 13rem;
        img {
          width: 13rem;
          position: relative;
          left: -0.9rem;
        }

        @include for-phone-only {
          margin: 0 auto;
          padding-bottom: 5rem;
        }
      }
    }

    .right {
      float: right;
      margin: 1em;
      @include for-phone-only {
        display: none;
      }
    }
  }

  section.latest-topics {
    // width: 60%;
    @include for-phone-only {
      max-width: 90%;
    }
    padding-left: 3rem;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);

    @include for-phone-only {
      padding-left: 1rem;

      width: 100%;
      h4 {
        margin-bottom: 1em;
      }
    }

    ul {
      display: inline-block;

      li {
        float: left;
        margin: 0.5rem;
        @include for-phone-only {
          margin: 0.5rem 1rem 1rem 0.5rem;
        }
        border-radius: 10px;
        background-color: $lightGreyColor;
        padding: 0.7rem 1rem;
        font-size: 0.9rem;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

        a {
          text-decoration: none;
          color: $darkBlueColor;

          img {
            width: 2em;
            height: 2em;
          }
        }
      }
    }
  }

  section.latest {
    width: 100%;
    // padding-left: 3rem;
    @include for-phone-only {
      padding: 0rem;
      margin: 1rem auto;
      width: 100%;
      h3 {
        margin-bottom: 1em;
      }
    }
    .post-list {
      width: 800px;
      @include for-phone-only {
        width: auto;
      }
      margin: 3rem auto;
      padding: 0;
      h1,
      h2,
      h3,
      h4,
      h5 {
        font-size: 1rem;
      }
      li {
        padding: 1em 2em;
        @include for-phone-only {
          padding: 0.5em 1em;
        }
        h2 {
          font-size: 1.3em;
          line-height: 1.5;
          @include for-phone-only {
            margin-bottom: 1rem;
          }
        }

        p {
          font-size: 1em;
          line-height: 1.4;
        }
      }
    }

    ul.pagination-links {
      text-align: center;
      border-top: 1px dotted $darkBlueColor;
      max-width: 20%;
      margin: 5rem auto;
      li {
        padding: 1em;

        a {
          text-decoration: none;
          font-style: italic;
        }
      }
      @include for-phone-only {
        max-width: none;
        padding: 0;
        margin: 0 0 10rem 0;
      }
    }
  }

  section.post-content {
    padding: 0.5em;
    margin-left: 12rem;
    margin-right: 11rem;

    // h5#information-drawn-from{
    //         background-color: yellow;
    // }

    // li ~ a

    @include for-phone-only {
      margin: 0;
    }

    @include for-desktop-up {
      max-width: 700px;
      line-height: 1.3;
      margin: 0 auto;
    }

    img {
      width: 2rem;
      display: inline-block;
      &.featured-img {
        width: 4rem;
      }
    }

    h2,
    h3,
    h4 {
      font-family: Lato-Bold;

      display: inline-block;
      position: relative;
      top: 0.2rem;
      margin-top: 3rem;
      margin-left: 0.6em;
      margin-bottom: 0em;
      &.main-header {
        // margin-bottom: 1.5em;
        font-size: 2.5rem;
      }
      @include for-phone-only {
        margin-left: 0.3em;
      }
    }

    h2 {
      font-size: 1.6em;
    }

    h3 {
      font-size: 1.5em;
    }
    h4 {
      font-size: 1.4em;
    }
    p {
      font-size: 1.2rem;
      // max-width: 80%;
      margin-bottom: 1em;
      line-height: 1.6;
      // font-family: TimesNewRoman-Regular;
      font-family: Lato-Light;

      @include for-phone-only {
        max-width: 100%;
        line-height: 1.4;
      }

      &.date {
        font-style: italic;
        font-size: 0.8em;
        padding-bottom: 1em;
        text-align: center;
        max-width: 100%;
      }
      img {
        width: 100%;
        @include for-phone-only {
          width: 90%;
        }
      }
    }

    ul {
      margin-left: 3rem;
      margin-top: 1rem;
      max-width: 80%;
      // font-family: TimesNewRoman-Regular;
      font-family: Lato-Light;

      font-size: 1.2rem;

      li {
        list-style-type: disc;
        margin-bottom: 1rem;
        line-height: 1.6;
      }
    }

    #--references-- {
      margin: 2rem 0;
    }

    #--references-- + ul {
      margin-left: 1em;
      li {
        list-style: none;
        margin-bottom: 1em;
      }
    }

    &.category {
      .header {
        // margin-left: 1rem;
        @include for-phone-only {
          margin: 0;
        }
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        padding: 1rem;
        margin-bottom: 2rem;
      }
      img {
        width: 2rem;
        display: inline-block;
      }
      h3 {
        display: inline-block;
        margin: 1rem;
        position: relative;
        top: 0.2rem;
      }
      .main {
        clear: both;
        float: none;
        ul {
          list-style-type: none;
          margin: 1rem;
          padding: 0;
        }
        li {
          list-style: none;
          font-family: SourceCodePro-Regular;
        }
      }
    }
  }

  blockquote {
    background-color: white;
    border-radius: 10px;
    padding: 0.5em 0.8em;
    // font-style: italic;
    font-family: TimesNewRoman-Italic;
    margin: 1em;
    max-width: 70%;
    > p {
      margin: 0.2em !important;
      max-width: initial !important;
      padding-left: 1rem;
      position: relative;
      top: -0.5rem;
      font-size: 1.2rem !important;
      font-family: TimesNewRoman-Italic !important;
    }
    @include for-phone-only {
      max-width: 100%;
      padding: 0.05em 0em;
    }
    &::before {
      content: "";
      width: 20px;
      height: 20px;
      background: url("../images/pin-icon.png") no-repeat;
      background-size: cover;
      top: -1px;
      right: 5px;
      position: relative;
      display: inline-block;
    }
  }
}

body > main > section > blockquote:nth-child(5) {
  background-color: $lightBlueColor;
  padding: 0.5rem 1rem;
  max-width: none;
  p,
  li {
    font-size: 0.9rem !important;
    font-family: Lato-Regular !important;
    color: white;
    a {
      font-family: Lato-Regular !important;
      color: white;
    }
  }
}

footer {
  width: 100%;
  font-size: 0.8em;
  position: fixed;
  clear: both;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5em 0;
  background-color: $primary-color;
  z-index: 11111;
  background: white;
  // border-top: 1px solid;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}
.current {
  color: green;
}
