section.about-header {
    p {
        font-size: 1rem;
        margin: 0;
    }
}
section.about {
    max-height: 20rem;

    display: inline-block;

    ul {
        list-style-type: none;
    }

    .container {
        width: 100%;
        li  {
            padding-right: 1rem;
        }
    }

    .avatar {
        float: left;
        width: 18rem;
        background-image: url(../images/mifoto_menos_pelo.jpg);
        background-size: 18rem 15rem;
        background-position: 0;
        height: 15rem;
        vertical-align: bottom;
        position: relative;
        margin-right: 1rem;
        @include for-phone-only {
            margin-right: 0;
            width: 90%;
            float: none;
            background-size: contain;
            background-repeat: no-repeat;
            height: 16rem;
        }

        h1 {
            vertical-align: bottom;
            position: absolute;
            bottom: 1rem;
            right: 0.5rem;
            color: lightgrey;
            @include for-phone-only {
                bottom: auto;
                top: 1rem;
                font-size: 0.7rem;
            }
        }
    }

    .contact-info {
        float: left;
        text-align: left;
        position: relative;
        margin-right: 3rem;

        @include for-phone-only {
            float: none;
            display: inline-block;
            width: 100%;
            text-align: center;
        }

        .social-links {
            @include for-phone-only {
                display: inline-block;
            }
            li {
                @include for-phone-only {
                    float: left;
                    margin-right: 1rem;
                }
                img {
                    width: 2rem;
                    @include for-phone-only {
                        width: 2rem;
                    }
                }
                margin-bottom: 1rem;
            }
        }

        p {
            position: absolute;
            left: 0;
            top: 18rem;
            padding: 0;
            margin: 0;
            width: 21rem;
        }
    }

    .skills {
        float: left;
        @include for-phone-only {
            float: none;
            display: inline-block;
            width: 100%;
            margin-top: 2rem;
        }
        li {
            position: relative;
            display: table;

            margin-bottom: 1rem;
            &::before {
                content: "\003e";
                font-size: 1.5rem;
                display: inline-block;
                color: red;
                padding: 0 6px 0 0;
                position: relative;
                top: 3px;
            }
            &::after {
                content: "";
                position: absolute;
                top: 20px;
                right: -12px;
                /* Remove display: inline-block if not required to be on the same line as text etc */
                display: inline-block;
                background-color: red;
                vertical-align: top;
                width: 10px;
                /* Set height to the line height of .text */
                height: 2px;
                /* 
                Animation paramaters:
                blink = animation-name, 
                1s = animation-duration, 
                step-end = animation-timing-function,
                infinite = animation-iteration-count
                */
                -webkit-animation: blink 1s step-end infinite;
                animation: blink 1s step-end infinite;
                @include for-phone-only {
                    display: none;
                }
            }
        }
    }
}

section.about-kofi {
    @include for-phone-only {
        margin-top: 4rem;
        margin-bottom: 3rem;
        text-align: center;
    }
}

@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
